<template>
  <div class="pbf grid_width">
    
    <br>

    <GeneralSearchTags 
      :tags="props.intags" 
      :tagsselected="tagsselected" 
      :tagsdefault="props.intags" 
      :tagscount="gallery_data?.items_tag"    
      :is-show-counts="false"
      @choose="chooseTags"
      @unchoose="unchooseTags"
    />

    <div class="sortb">
      <UiSort 
        @choose="chooseSort"
        :val="sort"    
        :align="'right'"
        :options="sortOptions"
      />
    </div>

    
    <SkPhotos 
      v-if="is_loading && start == 0"
    />
      
    <div v-else class="gallery_rows" id="gallery">
      <GeneralPhoto 
        v-for="(opt,ind) in gallery_data?.items"
        :key="ind"  
        :data="opt"    
        @clickPhoto="openGallery(ind)"  
      >       
      </GeneralPhoto>    
    </div>

    <ClientOnly>        
      <UiInfinityScroll 
        v-if="gallery_data?.items?.length >= limit"
        :is-loading="is_loading"
        ref="elInfinityScroll"
      />
    </ClientOnly>


    <GeneralLightbox 
      v-if="lightboxShow"
      :index="lightboxIndex" 
      :photos="lightboxPhotos" 
      @close="lightboxShow = false" 
    />
  

  </div>
</template>

<script setup lang="ts">

import { getTagsKeyword, getTagsKeys, getTagsSimple, getTagsWeek } from '@/types/other'
import { useIntersectionObserver } from '@vueuse/core'
import { watchArray } from '@vueuse/core'

// import PhotoSwipe from 'photoswipe';
// import 'photoswipe/style.css';

const isEnded = ref(false)
const {$api, $ga} = useNuxtApp()
const elInfinityScroll = ref(null)

const props = defineProps({
  inurl: {
    type: String,
    required: true
  },
  intags: {
    type: Object,
    required: true
  },
  brandId: {
    type: Number,
    default: null
  },
  productId: {
    type: Number,
    default: null
  },
  growerId: {
    type: Number,
    default: null
  },
  strainId: {
    type: Number,
    required: false
  }
})
const lightboxShow = ref(false);
const lightboxIndex = ref(0);

const start = ref(0);
const limit = ref(20);
const query = ref('');
const sort = ref('week_update_desc');
const sortOptions = ref([
  {id:'week_update_desc', tr: 'universal_sort_last_update', icon: 'desc'},    
  {id:'week_update_asc', tr: 'universal_sort_last_update', icon: 'asc'}, 
  {id:'create_desc', tr: 'universal_sort_creation_date', icon: 'desc'},
  {id:'create_asc', tr: 'universal_sort_creation_date', icon: 'asc'},
]);
const tagscount = ref([]);
const tagsselected = ref([]);
const tagspermanent = ref([]);
const tagsdefault = ref(null);
const list = ref([]);



if(props.strainId){
  tagspermanent.value.push('st:' + props.strainId);
}

if(props.growerId){
  tagspermanent.value.push('us:' + props.growerId);
}

if(props.brandId){
  tagspermanent.value.push('br:' + props.brandId);
}

if(props.productId){
  tagspermanent.value.push('pr:' + props.productId);
}



const lightboxPhotos = computed(() => {
  var items = [];
  for(var i of gallery_data.value?.items){
    let photo = {}; 
    if(i.is_video){
      photo.type = 'iframe';
      photo.iframe = '/video/' + i.video_id;
    }          
    photo.base = i.size_big;
    photo.zoom = i.size_big; 
    items.push(photo);        
  }
  return items;
})

const openGallery = function(key){
  console.log('open');
  lightboxShow.value = true;
  lightboxIndex.value = key;
}
 
const clearPagination = function(){     
  start.value = 0;  
}


const unchooseTags = function(selected){  
  tagsselected.value = tagsselected.value.filter((item) => item != selected);
}        
const chooseTags = function(selected){          
  tagsselected.value.push(selected);      
  clearPagination();    
  $ga.eventGA('explore', 'tag', getTagsSimple(props.intags, tagsselected.value, tagspermanent.value));
}

const chooseSort = function(selected_sort){   
  sort.value = selected_sort;    
  clearPagination();  
}
 

// const openGallery = function(key){ 
   
//   var lightbox = new PhotoSwipe({
//     gallery: '#gallery',
//     children: 'a',
//     dataSource: list.value,
//     index: key,
//     showHideAnimationType: 'none',                     
//   })
    
//   lightbox.init();
// }




// const infiniteHandler = function(state){
//   start.value += limit.value;
//   loadResults(state);
// }

// const loadResults = async function(state){

//   var dt = await $api.getUrl(inurl, {        
//     start: start.value,
//     limit: limit.value,
//     q: query.value,
//     sortable: sort.value,
//     tags: tagsselected.value.join(',')                       
//   })
        
  
//   if(state && !dt.items.length) state.complete();
//   if(state && dt.items.length) state.loaded();    
  
  
//   tagscount.value = dt.items_tag;    
//   list.value.push(...dt.items);

// }


const loadData = async function() {  
  const response = await $api.getUrl(props.inurl, {
    start: start.value,
    limit: limit.value,
    q: getTagsKeyword(props.intags, tagsselected.value),   
    week: getTagsWeek(props.intags, tagsselected.value), 
    sortable: sort.value,
    tagsall: getTagsKeys(props.intags),
    tags: getTagsSimple(props.intags, tagsselected.value),
    tagsper: getTagsSimple(props.intags, tagspermanent.value)
  });
  return response;  
}



const { pending: is_loading,  data: gallery_data } = await useLazyAsyncData('gallery_data', async () => await loadData())


watchArray([start, sort, tagsselected], async ([new_start, new_sort, new_tagsselected], added, removed) => {
  
  if(new_start == 0){
    gallery_data.value.items = [];
    isEnded.value = false;
  }
  
  console.log('watcher');
  is_loading.value = true;
  var dt = await loadData();

    
  if(dt.length == 0)
    isEnded.value = true;  

  gallery_data.value.items = [...gallery_data.value.items, ...dt.items]
  if(new_start === 0)
    gallery_data.value.items_tag = dt.items_tag
  is_loading.value = false;

  // $adv.apply();

}, {deep: true})



useIntersectionObserver(
  elInfinityScroll,
  ([{isIntersecting}]) => {
    if(isIntersecting){
      console.log('infinity scroll event');        
      if(!is_loading.value && !isEnded.value)
        start.value = start.value + limit.value;
    }
  },
  { distance: 10 }
)



</script>

<style scoped>


.add_filter_box{
  display: flex;
  margin-left: auto;
}

.gallery_rows{
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.sortb{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

</style>
